// @ts-check
import {z} from 'zod'

/**
 * Specify your server-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 */
export const serverSchema = z.object({
	// Application Insights
	ENABLE_CONSOLE_LOG: z.preprocess((val) => {
		if (typeof val === 'boolean') return val
		if (typeof val === 'string')
			return ['true', 'false'].includes(val) ? val === 'true' : false
		return false
	}, z.boolean()),
	// Kontent.ai - get these from the Kontent project you want to pull from
	KONTENT_PREVIEW_API_KEY: z.string(),
	KONTENT_PREVIEW_SECRET: z.string(),
	GLOBAL_CONFIG_NAME: z.string().default('global_config'),
	// Get these from the Pet Names Finder (COM-NAME)
	PET_NAMES_URL: z.string().url().optional(),
	PET_NAMES_API_KEY: z.string().optional(),
	// Get these from CDC
	CDC_DATACENTER: z.string(),
	CDC_API_KEY: z.string().optional(), // TODO: key isn't used in codebase and can be removed - api key is set via Global Config in Kontent.ai
	CDC_API_USER_KEY: z.string(),
	CDC_API_SECRET: z.string(),
	// Get these from the Disease API
	DISEASE_API: z.string().url(),
	DISEASE_API_SUBSCRIPTIONKEY: z.string(),
	DISEASE_API_TOKEN_URL: z.string().url(),
	DISEASE_API_CLIENTID: z.string(),
	DISEASE_API_CLIENTSECRET: z.string(),
	// Get these from Business Finder
	FIND_A_BUSINESSMAP_URL: z.string().url(),
	FIND_A_BUSINESS_API_KEY: z.string(),
	BUSINESS_FUZZY_SEARCH_URL: z.string().optional(),
	// Get these from SendGrid
	SENDGRID_API_KEY: z.string(),
	// Get this from Google
	GOOGLE_GEOCODE_KEY: z.string(),
	CONSUMER_ENGAGEMENT_GOOGLE_APIKEY: z.string().optional(),
	// Consumer Engagement API
	PET_ENDPOINT: z.string(),
	// Veeva
	VEEVA_DOMAIN: z.string(),
	VEEVA_VER: z.string(),
	VEEVA_USER: z.string().optional(),
	VEEVA_PASS: z.string().optional(),
	// Campaign Interest Service
	CAMPAIGN_INTEREST_API_URL: z.string(),
	CAMPAIGN_API_APP_SCOPE: z.string(),
	TENANT_ID: z.string(),
	/**
	 * Service Principal Credentials To Make
	 * Authenticated Calls To Other Elanco Applications
	 * TODO: Revisit Bringing In Client Id/Secret
	 * Of Other Applications (ex. DISEASE_API*)
	 */
	AZURE_SERVICE_PRINCIPLE_CLIENT_ID: z.string(),
	AZURE_SERVICE_PRINCIPLE_CLIENT_SECRET: z.string(),

	LAKEHOUSE_API: z.string().url(),
	LAKEHOUSE_API_SUBSCRIPTIONKEY: z.string(),
})

/**
 * Specify your client-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 * To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
export const clientSchema = z.object({
	NEXT_PUBLIC_KONTENT_PROJECT_ID: z.string(),
	NEXT_PUBLIC_APPINSIGHTS_CONNECTION_STRING: z.string(),
	NEXT_PUBLIC_TOWER: z.enum(['pet', 'vet', 'farm', 'corporate']),
	NEXT_PUBLIC_SEARCH_API_KEY: z.string(),
	NEXT_PUBLIC_MAP_API_KEY: z.string(),
	NEXT_PUBLIC_PARASITE_MAP_STYLE_ID: z.string(),
	NEXT_PUBLIC_FAQ_COGNITIVE_SEARCH_API_KEY: z.string(),
	NEXT_PUBLIC_APP_URL: z.string(),
	NEXT_PUBLIC_REBATES_API_URL: z.string().optional(),
	NEXT_PUBLIC_TRUSTCODE_HOST: z.string().optional(),
	NEXT_PUBLIC_TRUSTCODE_REPO_KEY: z.string().optional(),
	NEXT_PUBLIC_FEATURE_FLAG_COUNTRY_SELECTOR: z.string(),
	NEXT_PUBLIC_NODE_ENV: z.enum(['development', 'production', 'test']),
})

/**
 * @type {"pet" | "vet" | "farm" | "corporate" | undefined}
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore -- Hack to cast string to type 'pet' | 'vet' | 'farm' | 'corporate' | undefined
const TOWER = process.env.NEXT_PUBLIC_TOWER

/**
 * You can't destruct `process.env` as a regular object, so you have to do
 * it manually here. This is because Next.js evaluates this at build time,
 * and only used environment variables are included in the build.
 * @type {{ [k in keyof z.infer<typeof clientSchema>]: z.infer<typeof clientSchema>[k] | undefined }}
 */
export const clientEnv = {
	NEXT_PUBLIC_KONTENT_PROJECT_ID:
		process.env.NEXT_PUBLIC_KONTENT_PROJECT_ID ?? '',
	NEXT_PUBLIC_APPINSIGHTS_CONNECTION_STRING:
		process.env.NEXT_PUBLIC_APPINSIGHTS_CONNECTION_STRING,
	NEXT_PUBLIC_TOWER: TOWER,
	NEXT_PUBLIC_SEARCH_API_KEY: process.env.NEXT_PUBLIC_SEARCH_API_KEY,
	NEXT_PUBLIC_MAP_API_KEY: process.env.NEXT_PUBLIC_MAP_API_KEY,
	NEXT_PUBLIC_FAQ_COGNITIVE_SEARCH_API_KEY:
		process.env.NEXT_PUBLIC_FAQ_COGNITIVE_SEARCH_API_KEY,
	NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
	NEXT_PUBLIC_REBATES_API_URL: process.env.NEXT_PUBLIC_REBATES_API_URL,
	NEXT_PUBLIC_TRUSTCODE_HOST: process.env.NEXT_PUBLIC_TRUSTCODE_HOST,
	NEXT_PUBLIC_TRUSTCODE_REPO_KEY: process.env.NEXT_PUBLIC_TRUSTCODE_REPO_KEY,
	NEXT_PUBLIC_FEATURE_FLAG_COUNTRY_SELECTOR:
		process.env.NEXT_PUBLIC_FEATURE_FLAG_COUNTRY_SELECTOR,
	NEXT_PUBLIC_NODE_ENV: process.env.NODE_ENV,
	NEXT_PUBLIC_PARASITE_MAP_STYLE_ID:
		process.env.NEXT_PUBLIC_PARASITE_MAP_STYLE_ID,
}
