import {PageCountDlType} from '@/models/PageProps'
import {useRouter} from 'next/router'
import {useState, useEffect, useRef} from 'react'
// import {setCookie} from 'cookies-next'
import {env} from './env/client.mjs'
import {minutesDiff} from './helpers'
import {
	AdvancedPageTemplateContentItem,
	ArticleConfigContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import {JsonObject} from '@/_new-code/products/auth/cdc-screensets/models'
import {CdcUser} from '@/models/CdcUserModels'

export function useTrustArcCookieValue() {
	const [trustArcCookie, setTrustArcCookie] = useState<string>()
	const [latestCookie, setLatestCookie] = useState('')
	const intervalRef = useRef<undefined | NodeJS.Timer>()

	useEffect(() => {
		if (document !== undefined) {
			setLatestCookie(document.cookie)
		}
		const detectCookieUpdate = () =>
			document.cookie !== latestCookie && setLatestCookie(document.cookie)
		const interval = setInterval(detectCookieUpdate, 1000)
		intervalRef.current = interval

		return () => clearInterval(intervalRef.current)
	}, [])

	useEffect(() => {
		if (trustArcCookie !== undefined) {
			clearInterval(intervalRef.current)
		}
	}, [trustArcCookie])

	useEffect(() => {
		const cookieValue = latestCookie
			.split('; ')
			.find((row) => row.startsWith('notice_preferences='))
			?.split('=')[1]
		setTrustArcCookie(cookieValue)
	}, [latestCookie])

	return trustArcCookie
}

/** Helper function to push data layer events. Use this instead of window.dataLayer.push(...) */
export const pushToDataLayer = (...data: Record<string, unknown>[]) => {
	window.dataLayer = window.dataLayer || []
	window.dataLayer.push(...data)
}
// product information
const getPageData = (page: Tersed<AdvancedPageTemplateContentItem>) => {
	const pageConfig = page.elements.config[0]

	let animal: string[] = []

	if (pageConfig && 'species' in pageConfig.elements) {
		const articleConfig = pageConfig as Tersed<ArticleConfigContentItem>
		animal = articleConfig.elements.species
			? articleConfig.elements.species.map(({elements: {name}}) =>
					name.trim()
				)
			: ['(not set)']
	}

	let productName = '(not set)'

	if (
		pageConfig?.system.type === 'config_product' ||
		pageConfig?.system.type === 'config_brand'
	) {
		productName = pageConfig.system.name.replace(/(\r\n|\n|\r)/gm, '')
	}

	return {
		productName,
		animal,
	}
}

const pageEvents = (
	page: Tersed<AdvancedPageTemplateContentItem>,
	userId: string | null,
	locale: string
) => {
	const includeUserId = env.NEXT_PUBLIC_TOWER === 'vet'

	const pageViewData: Record<string, any> = {
		event: 'page_view',
		country: locale,
		page_template: page.system.type,
		portal: env.NEXT_PUBLIC_TOWER,
		...getPageData(page),
	}

	if (includeUserId) {
		pageViewData['user_id'] = userId ? userId : '(not set)'
	}

	pushToDataLayer(pageViewData)

	if (
		page.system.type === 'article' ||
		page.system.type === 'article_v1' ||
		page.system.type === 'article_v2' ||
		page.system.type === 'article_v3'
	) {
		pushToDataLayer({
			event: 'article_info',
			page_template: page.system.type,
			article_name: page.system.name,
			...getPageData(page),
		})
	}
}
const errordataLayer = () => {
	pushToDataLayer({
		event: 'error_404',
		page_url: window.location.href,
	})
}

export const sendScreenSetEventToDataLayer = (
	response: JsonObject,
	formType: string,
	blockCodename: string = '',
	campaignId: string = ''
): void => {
	const {errorCode, socialProviders, newUser, UID: user_id = ''} = response
	const statusCode = Number(errorCode)

	const SCREENSET_IDS = {
		LOGIN: 'gigya-login-form',
		REGISTER: 'gigya-register-form',
		RESET_PASSWORD: 'gigya-reset-password-form',
		SOCIAL: 'div.gigya-social-login',
		SIGNUP_CAMPAIGN: 'gigya-subscribe-with-email-form',
	}

	const statusCodes = [
		206002, // Account created pending email verification
		0, // OK valid response
	]

	const provider: {[k: string]: string} = {
		site: 'Email',
		facebook: 'Facebook',
		googleplus: 'Google',
	}

	const status = statusCodes.includes(statusCode)
		? 'Successful'
		: 'Unsuccessful'

	switch (formType) {
		case SCREENSET_IDS.LOGIN: {
			pushToDataLayer({
				event: 'login',
				action: 'Login',
				status,
				method: provider.site,
				user_id,
			})

			break
		}
		case SCREENSET_IDS.SOCIAL: {
			if (statusCodes.includes(statusCode) && socialProviders) {
				if (newUser) {
					pushToDataLayer({
						event: 'signup_completed',
						action: 'Registration Completed',
						method: provider[socialProviders],
						user_id,
					})
				} else {
					pushToDataLayer({
						event: 'login',
						action: 'Login',
						status,
						method: provider[socialProviders],
						user_id,
					})
				}
			}
			break
		}
		case SCREENSET_IDS.REGISTER: {
			pushToDataLayer({
				event: 'signup_initiated',
				action: 'Registration Initiated',
				status,
				method: provider.site,
				user_id,
			})
			break
		}
		case SCREENSET_IDS.RESET_PASSWORD: {
			pushToDataLayer({
				event: 'login',
				action: 'Login',
				status:
					status === 'Successful'
						? 'Forgot Password'
						: 'Unsuccessful',
				method: 'Email',
			})

			break
		}
		case SCREENSET_IDS.SIGNUP_CAMPAIGN: {
			const submitButtons =
				document.getElementsByClassName('gigya-input-submit')

			const buttonElements = Array.from(submitButtons)
			const valuesArray: string[] = []

			if (buttonElements.length > 0) {
				buttonElements.forEach((ele) => {
					if (ele instanceof HTMLInputElement) {
						valuesArray.push(ele.value)
					}
				})
			}

			if (blockCodename === 'cdc_screen_set_wrapper' && campaignId) {
				pushToDataLayer({
					event: 'signup_campaign',
					action: 'Signup Completed',
					status,
					campaign: campaignId,
					user_id,
				})
				if (valuesArray.length > 2) {
					pushToDataLayer({
						event: 'cta_click',
						cta_name: valuesArray[2],
						cta_category: 'CTA_Button',
					})
				}
			}

			break
		}
		default:
	}
}

const getScrollDepth = (): number => {
	const rootNode = document.documentElement
	const documentBody = document.body

	const scrollDepth =
		((rootNode.scrollTop || documentBody.scrollTop) /
			((rootNode.scrollHeight || documentBody.scrollHeight) -
				rootNode.clientHeight)) *
		100

	return Math.round(scrollDepth)
}

const addScrollDepthDataLayer = (slug: string): void => {
	let thresholds = [25, 50, 75, 100]

	const handleScroll = () => {
		if (thresholds.length === 0) {
			window.removeEventListener('scroll', handleScroll)
			return
		}

		const scrollDepth = getScrollDepth()

		thresholds.forEach((threshold) => {
			if (scrollDepth >= threshold && scrollDepth <= threshold + 25) {
				pushToDataLayer({
					event: 'scroll_depth',
					scroll_depth_threshold: String(threshold),
				})

				thresholds = thresholds.filter((item) => item !== threshold)
			}
		})
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [slug])
}

// function getUserCookieExpirationDate(): Date {
// 	const now = new Date()
// 	const time = now.getTime()
// 	const expireTime = time + 1000 * 60 * 30
// 	now.setTime(expireTime)
// 	return now
// }
// const getConsent = () => {
// 	const result = ''
// 	const rx = /\s*cmapi_cookie_privacy\s*=\s*([^;]*)/.exec(document.cookie)
// 	if (rx && rx.length > 1) {
// 		return rx[1]
// 	}
// 	return result
// }

// const search = (origin: string, countryPage: string, myArray: any) => {
// 	const arrayPage: CookieVal[] = myArray
// 	let flag: boolean = true
// 	myArray.forEach((ele: CookieVal, index: number) => {
// 		const {country, pageviewCount, domain} = ele
// 		if (domain === origin && countryPage === country) {
// 			flag = false
// 			if (pageviewCount >= 1) {
// 				pushToDataLayer({
// 					event: 'navigation_engagement',
// 					country,
// 					pagevisit: pageviewCount + 1,
// 				})
// 			}
// 			arrayPage[index] = {
// 				pageviewCount: pageviewCount + 1,
// 				domain,
// 				country,
// 			}
// 			setCookie('pagecountDl', JSON.stringify(arrayPage), {
// 				expires: getUserCookieExpirationDate(),
// 			})
// 		}
// 	})
// 	return flag
// }

// const SearchCookieComponent = () => {
// 	const router = useRouter()
// 	useEffect(() => {
// 		if (document !== undefined) {
// 			if (!getCookie('pagecountDl')) {
// 				setCookie(
// 					'pagecountDl',
// 					JSON.stringify([
// 						{
// 							pageviewCount: 1,
// 							domain: window.origin || '',
// 							country: router.locale,
// 						},
// 					]),
// 					{
// 						expires: getUserCookieExpirationDate(),
// 					}
// 				)
// 			} else {
// 				const pageSearchList = JSON.parse(
// 					getCookie('pagecountDl')?.toString() ?? ''
// 				) as CookieVal[]
// 				const flagValue = search(
// 					window?.origin ?? '',
// 					router?.locale ?? '',
// 					pageSearchList
// 				)
// 				if (flagValue) {
// 					pageSearchList.push({
// 						pageviewCount: 1,
// 						domain: window.origin || '',
// 						country: router.locale,
// 					})
// 					setCookie('pagecountDl', JSON.stringify(pageSearchList), {
// 						expires: getUserCookieExpirationDate(),
// 					})
// 				}
// 			}
// 		}
// 	}, [])
// }

const addDataLayerEventsToLinks = (ele: MouseEvent) => {
	let targetElement = ele.target
	while (targetElement && !(targetElement instanceof HTMLAnchorElement)) {
		const targetNode = targetElement as HTMLElement
		targetElement = targetNode.parentNode
	}
	if (targetElement instanceof HTMLAnchorElement) {
		if (
			targetElement.href !== '' &&
			targetElement.innerText !== undefined
		) {
			if (targetElement.hostname === window.location.hostname) {
				pushToDataLayer({
					event: 'internal_link_click',
					element_text: targetElement.innerText,
					link_url: targetElement.href,
				})
			} else if (
				targetElement.hostname === 'www.elancolabels.com' ||
				targetElement.hostname === 'elancolabels.com' ||
				targetElement.href.endsWith('.pdf')
			) {
				pushToDataLayer({
					event: 'pdf_download',
					element_text: targetElement.innerText,
					link_url: targetElement.href,
				})
			} else {
				pushToDataLayer({
					event: 'external_link_click',
					element_text: targetElement.innerText,
					link_url: targetElement.href,
				})
			}
		}
	}
}
const handleLinkClick = (event: MouseEvent) => {
	const clickedElement = event.target as HTMLElement

	if (
		clickedElement.tagName.toLowerCase() === 'a' ||
		clickedElement.closest('a') !== null
	) {
		addDataLayerEventsToLinks(event)
	}
}

const addinternalAndExternalLinkEvents = () => {
	document.body.addEventListener('click', handleLinkClick)
}

const addSignUpCompletedFromMailDataLayer = (userId: string | null) => {
	pushToDataLayer({
		event: 'signup_completed',
		user_id: userId,
		action: 'Registration Completed',
		method: 'Email',
	})
}

const cleanUpInternalAndExternalLinkEvents = () => {
	document.body.removeEventListener('click', handleLinkClick)
}

const addNavigationEngagementDataLayer = () => {
	const defaultObj = {visitedPages: 0, lastVisitedPageTime: new Date()}
	const pageData = JSON.parse(
		localStorage.getItem('pageCountDl') || JSON.stringify(defaultObj)
	) as PageCountDlType
	let updatedNumber = pageData.visitedPages + 1

	// if user is inactive for 30 minutes then restart the sequence
	if (minutesDiff(new Date(), new Date(pageData.lastVisitedPageTime)) > 30) {
		updatedNumber = 1
		// Clear the pageCountDl item from localStorage
		localStorage.removeItem('pageCountDl')
	}
	pushToDataLayer({
		event: 'navigation_engagement',
		pagevisit: updatedNumber,
	})
	localStorage.setItem(
		'pageCountDl',
		JSON.stringify({
			visitedPages: updatedNumber,
			lastVisitedPageTime: new Date(),
		})
	)
}

export function useAnalytics(page: Tersed<AdvancedPageTemplateContentItem>) {
	const {locale = '', ...router} = useRouter()
	// if (getConsent() === 'permit 1,2,3') SearchCookieComponent()
	const authState = useAuth()
	const userId = authState?.userDetails?.id
	const slug = page.elements.pageUrl
	addScrollDepthDataLayer(slug)
	const handlePageViewEvent = (accountInfo: CdcUser) => {
		if (accountInfo && accountInfo.UID)
			pageEvents(page, accountInfo.UID, locale)
		else pageEvents(page, null, locale)
	}

	useEffect(() => {
		addNavigationEngagementDataLayer()
		addinternalAndExternalLinkEvents()
		if (router.query?.errorCode === '0') {
			addSignUpCompletedFromMailDataLayer('unknown')
		}
		if (slug === '/404') errordataLayer()

		if (
			env.NEXT_PUBLIC_TOWER === 'pet' ||
			env.NEXT_PUBLIC_TOWER === 'farm'
		) {
			pageEvents(page, null, locale)
		}

		return () => {
			cleanUpInternalAndExternalLinkEvents()
		}
	}, [slug])

	useEffect(() => {
		if (router.query?.errorCode === '206005' && userId !== null) {
			addSignUpCompletedFromMailDataLayer(userId)
		}
	}, [userId])

	useEffect(() => {
		if (env.NEXT_PUBLIC_TOWER === 'vet') {
			if (authState.ready) {
				window.gigya?.accounts.getAccountInfo({
					callback: handlePageViewEvent,
				})
			}
		}
	}, [slug, authState.ready, locale])
}
